import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { clone, remove, uniqBy } from 'lodash-es';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { retry } from 'rxjs/operators';
import { MarketsService, SongsService } from 'sostereo-services';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { SearchTagsService } from 'src/app/angular/shared/services/search-tags.service';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { usaStates } from '../../../../shared/configs/location.config';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgFor, NgIf, NgClass } from '@angular/common';
@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgIf, TooltipModule, BsDatepickerModule, FormsModule, NgClass, TypeaheadModule],
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {
  @Output() removeTag: EventEmitter<any> = new EventEmitter();
  @Output() sendSearch: EventEmitter<any> = new EventEmitter();
  @Input() isAdmin: boolean;
  @Input() currentUrl: string;
  public ownersDropdown = [];
  public totalOwnersArray;
  public countriesDropdown = [];
  public statesDropdown = usaStates;
  private $eventSettingList: Subscription;
  private requestError: any = {};
  public embeddedVersions = [
    { name: 'V1', id: 'v1', value: this.searchTagsService.embeddedVersion === 'v1' },
    { name: 'V2', id: 'v2', value: this.searchTagsService.embeddedVersion === 'v2' },
  ];

  public multiSelect = {
    labels: {
      data: [],
    },
    releaseStatus: {
      data: [
        { id: 'released-without-date', name: 'Released Without Date' },
        { id: 'released-with-date', name: 'Released With Date' },
        { id: 'released-for-synch', name: 'Released For Synch' },
        { id: 'unreleased', name: 'Unreleased' },
      ],
    },
  };

  constructor(
    public searchTagsService: SearchTagsService,
    public commonService: CommonService,
    private songsService: SongsService,
    private marketsService: MarketsService,
    private trackingService: TrackingService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
  ) {
    this.$eventSettingList = this.searchTagsService.eventSettingList.subscribe(() => {
      this.embeddedVersions = [
        { name: 'V1', id: 'v1', value: this.searchTagsService.embeddedVersion === 'v1' },
        { name: 'V2', id: 'v2', value: this.searchTagsService.embeddedVersion === 'v2' },
      ];
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.$eventSettingList.unsubscribe();
  }

  ngOnInit(): void {
    this.loadCountries();
    if (this.isAdmin) {
      this.loadContractOwners({ distinct: 'contractOwner' });
    }
  }

  public getDataByDate(setting, model, idx1, idx2) {
    if (!model || model.length <= 1) {
      this.searchTagsService.settingsList[idx1].items[idx2].value = null;
      model = null;
      this.removeTag.emit({
        category: 'advanced-search',
        label: setting.label,
        slug: setting.slug,
        idx1: idx1,
        idx2: idx2,
      });
    } else {
      const startOf = moment(model[0]).startOf('day');
      const endOf = moment(model[1]).endOf('day');
      this.searchTagsService.settingsList[idx1].items[idx2].value = `${
        setting.filter
      }After=${startOf.format('YYYY-MM-DD')}&${setting.filter}Before=${endOf.format('YYYY-MM-DD')}`;
      this.searchTagsService.addTag({
        category: 'advanced-search',
        label: setting.label,
        slug: setting.slug,
        idx1: idx1,
        idx2: idx2,
      });
    }
    this.sendSearch.emit();
  }

  private removeOldElements(setting, option, idx1, idx2) {
    remove(this.searchTagsService.settingsList[idx1].items[idx2].resultArray, (rs: any) => {
      if (option.slug === `country`) {
        return (
          rs.key === `${setting.slug}.country` ||
          rs.key === `${setting.slug}.state` ||
          (rs.key === `${setting.slug}.city` && option.value)
        );
      } else if (option.slug === 'state') {
        return (
          rs.key === `${setting.slug}.state` || (rs.key === `${setting.slug}.city` && option.value)
        );
      } else if (option.slug === 'city') {
        return rs.key === `${setting.slug}.city`;
      } else {
        return rs.key === `${setting.slug}.${option.slug}`;
      }
    });
  }

  setNullOtherTypes(option, type) {
    if (option.types) {
      option.types.forEach((element) => {
        if (element.slug !== type.slug) {
          element.value = null;
        }
      });
    }
  }

  public changeRecordingLocation(setting, type, option, idx1, idx2) {
    this.removeOldElements(setting, option, idx1, idx2);
    if (this.currentUrl.includes('/artists-list') && setting.slug === 'artistLocation') {
      setting.slug = 'location';
      setting.filter = 'location';
      setting?.value?.replace('artistLocation', 'location');
    }
    if (type.value) {
      this.setNullOtherTypes(option, type);
      this.searchTagsService.settingsList[idx1].items[idx2].resultArray.push({
        key: `${setting.slug}.${option.slug}`,
        value: type.slug,
      });
    }
    if (
      this.searchTagsService.settingsList[idx1].items[idx2].resultArray.length > 1 ||
      (this.searchTagsService.settingsList[idx1].items[idx2].resultArray.length > 0 &&
        (this.currentUrl.includes('/artists-list')
          ? setting.slug === 'location'
          : setting.slug === 'artistLocation'))
    ) {
      this.searchTagsService.settingsList[idx1].items[idx2].value = null;
      // To create query filter
      this.searchTagsService.settingsList[idx1].items[idx2].resultArray.forEach((elm) => {
        this.searchTagsService.settingsList[idx1].items[idx2].value =
          (this.searchTagsService.settingsList[idx1].items[idx2].value
            ? this.searchTagsService.settingsList[idx1].items[idx2].value + '&'
            : '') +
          elm.key +
          '=' +
          elm.value;
      });
      this.searchTagsService.addTag({
        category: 'advanced-search',
        label: setting.label,
        slug: `${setting.slug}`,
        idx1: idx1,
        idx2: idx2,
      });
      this.sendSearch.emit();
    } else {
      this.searchTagsService.settingsList[idx1].items[idx2].value = null;
      this.removeTag.emit({
        category: 'advanced-search',
        label: setting.label,
        slug: `${setting.slug}.${option.slug}`,
        idx1: idx1,
        idx2: idx2,
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  public changeLocation(setting, option, location, idx1, idx2, negative?) {
    option.negative = negative ? true : false;
    let locationName = option[location];
    if (negative) {
      locationName = `! ${option[location]}`;
    }
    option[location] = option[location] && option[location] !== '' ? option[location] : null;
    this.changeRecordingLocation(
      setting,
      { value: locationName, slug: locationName, kind: 'location' },
      { slug: location },
      idx1,
      idx2,
    );
  }

  public selectItemMultiSelect(setting, idx1, idx2) {
    const itemSelected = this.multiSelect[setting.slug].data.find(
      (item) => item.name == setting.model,
    );
    if (!setting.resultArray.includes(itemSelected.id)) {
      setting.resultArray.push(itemSelected.id);
      this.searchTagsService.settingsList[idx1].items[idx2].value =
        this.searchTagsService.settingsList[idx1].items[idx2].resultArray.length === 0
          ? null
          : `${setting.slug}=${this.searchTagsService.settingsList[idx1].items[
              idx2
            ].resultArray.join()}`;
      this.searchTagsService.addTag({
        category: 'advanced-search',
        label: setting.label,
        slug: setting.slug,
        idx1: idx1,
        idx2: idx2,
      });
      this.sendSearch.emit();
    }
    setting.model = null;
  }

  public deselectItemMultiSelect(setting, labelElement, idx1, idx2) {
    remove(this.searchTagsService.settingsList[idx1].items[idx2].resultArray, (elm) => {
      return elm === labelElement;
    });
    if (this.searchTagsService.settingsList[idx1].items[idx2].resultArray.length === 0) {
      this.removeTag.emit({
        category: 'advanced-search',
        label: setting.label,
        slug: setting.slug,
        idx1: idx1,
        idx2: idx2,
      });
    }
    this.searchTagsService.settingsList[idx1].items[idx2].value =
      this.searchTagsService.settingsList[idx1].items[idx2].resultArray.length === 0
        ? null
        : `${setting.slug}=${this.searchTagsService.settingsList[idx1].items[
            idx2
          ].resultArray.join()}`;
    this.sendSearch.emit();
  }

  public changeEmbedding(version: { name: string; id: string; value: boolean }) {
    this.embeddedVersions.forEach((em) => {
      if (version.id !== em.id) {
        em.value = false;
      }
    });
    this.searchTagsService.embeddedVersion = version.value ? version.id : null;
    this.sendSearch.emit();
  }

  private loadContractOwners(query) {
    this.songsService
      .query(query)
      .pipe(retry(3))
      .subscribe(
        (res) => {
          this.ownersDropdown = res.data.items.map((m) => ({ id: m.email, name: m.email }));
          this.ownersDropdown = uniqBy(this.ownersDropdown, 'id');
          this.multiSelect.labels.data = clone(this.ownersDropdown);
        },
        (httpErr) => {
          this.requestError.labels = httpErr.error;
          this.trackingService.track('Error at marketsService', {
            error: httpErr?.error,
          });
        },
      );
  }

  private loadCountries() {
    this.marketsService
      .get()
      .pipe(retry(3))
      .subscribe(
        (res) => {
          this.countriesDropdown = res.data
            .map((m) => ({ id: m.isoAlpha2, name: m.name }))
            .filter((m) => m.id !== 'WO');
        },
        (httpErr) => {
          this.requestError.countries = httpErr.error;
          this.trackingService.track('Error at marketsService', {
            error: httpErr?.error,
          });
        },
      );
  }

  public requestValidation(key: string) {
    if (this.requestError[key]) {
      this.toastr.error(
        this.requestError[key]?.message,
        `There was a problem with the ${key} data`,
      );
    }
  }

  public changeTextOptions(setting, idx1, idx2) {
    if (setting.value) {
      this.searchTagsService.addTag({
        category: 'advanced-search',
        label: setting.label,
        slug: setting.slug,
        idx1: idx1,
        idx2: idx2,
      });
    } else {
      this.removeTag.emit({
        category: 'advanced-search',
        label: setting.label,
        slug: setting.slug,
        idx1: idx1,
        idx2: idx2,
      });
    }

    this.sendSearch.emit();
  }
}
